<template>
  <div
    id="case-index-container"
    fluid
    class="pl-3 pt-3"
  >
    <Loader
      v-if="loaderState"
      class="test-case-loader"
    />
    <template v-else>
      <section-header
        :title="$t('testCases')"
        :action-text="$t('createTestCase')"
      />
      <CaseManagement
        :quick-create="true"
        :cases="cases"
        @folder-select="onSelectFolder"
        @bulkRemove="onBulkRemove"
        @caseRemove="onCaseRemove"
        @update-cases="updateCases"
      />
    </template>
  </div>
</template>

<script>
import SectionHeader from '@/components/TestCases/SectionHeader.vue';
import CaseManagement from '@/components/Cases/CaseManagement'
import Loader from '@/components/base/Loader';
import makeCasesService from '@/services/api/case';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import { handleNetworkStatusError } from '@/mixins/redirect';
import { sleep } from '@/utils/util';

export default {
  components: {
    SectionHeader,
    CaseManagement,
    Loader
  },
  mixins: [handleNetworkStatusError],
  data() {
    return {
      cases: [],
      loaderState: false,
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    async onSelectFolder(folderUID = null) {
     
      await this.getCases(folderUID);

      if (this.$route.name == "CasesFolder" && this.$route.params.folderUid == folderUID)
        return;

      if (folderUID) {
        if (folderUID !== this.selectedFolderUid) {
          this.selectedFolderUid = folderUID
          this.$router.push(
            {
              name: 'CasesFolder',
              params: {
                folderUid: folderUID
              },
            }
          )
        }
      } else {
        folderUID = this.selectedFolderUid
      }
    },

    async getCases(folderUID) {
      if (!folderUID) return;
      const caseService = makeCasesService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      try {
        const response = await caseService.getFolderCases(handle, projectKey, folderUID);
        this.cases = response.data.cases;
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'fetchError', { item: 'cases' });
      }
    },
    async onCaseRemove(uid) {
      const caseService = makeCasesService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      try {
        await caseService.deleteCase(handle, projectKey, uid);
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'Test Case' });
        this.cases = this.cases.filter(item => item.uid !== uid);
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'deleteError', { item: 'case' });
      }
    },
    async onBulkRemove(payload) {
      const caseService = makeCasesService(this.$api);
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      try {
        await caseService.deleteCases(handle, projectKey, payload);
        this.cases = this.cases.filter(item => !payload.ids.includes(item.uid));
        showSuccessToast(this.$swal, 'deleteSuccess', { item: 'cases' });
      } catch (error) {
        this.redirectOnError(error.response.status);
        showErrorToast(this.$swal, 'deleteError', { item: 'cases' });
      }
    },
    async init() {
      
      this.loaderState = false;
      if (this.selectedFolderUid) {
        // On initial load, no folder is selected. The automatic selection is
        // propogated up after it is made by the child folder tree element.
        let loaderStateTimeout = null;
  
        loaderStateTimeout = setTimeout(() => {
          this.loaderState = true;
        }, 1000);
  
        try {
          await this.getCases();
        } catch (error) {
          showErrorToast(this.$swal, 'fetchError', { item: 'cases' });
        } finally {
          clearTimeout(loaderStateTimeout); 
          const hideLoader = async () => {
            await sleep(1000); 
            this.loaderState = false; 
          };
          hideLoader(); 
        }
      }
    },
    updateCases(newCases) {
      this.cases = newCases;
    },
  }
}
</script>
<style>
.position-relative {
  position: relative !important;
}
.test-case-loader {
  display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}
</style>
